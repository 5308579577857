import React from "react"
import Layout from "../components/layout"

const Privacy = props => {

    return ( 
        <Layout
            title = { 'Privacy Policy' }
        >
            <div className="site-About">
                <div 
                    style = {{
                        width: '100vw',
                        minHeight : '90vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        paddingTop: '15vh'
                    }}
                >
                    <h1>Privacy Policy</h1>
                    <p align = 'left'
                        style = {{
                            whiteSpace : 'pre-line',
                            width: '70vw',
                            marginTop: '10vh',
                            minHeight : '40vh',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}
                    >
                        <strong>BEFORE USING OUR SITES, PLEASE READ THIS PRIVACY POLICY CAREFULLY.</strong><br/>
This Privacy Policy is applicable to Siu & Sons Int'l Trading ("Company") and sets out our policy on the gathering and use of information on this site and our other sites (collectively "Sites"). The Company is committed to providing safe web sites for visitors of all ages and has implemented this Privacy Policy to demonstrate our firm commitment to your privacy. The Company complies with Canadian Federal and Provincial privacy laws and regulations including the Personal Information and Electronic Documents Act.
There may be links from our Sites to other web sites; note that this Privacy Policy applies only to our Sites and not to web sites of other companies or organizations to which our Sites may be linked. You must check on any linked sites for the privacy policy that applies to that site and/or make any necessary inquiries in respect of that privacy policy with the operator of the linked site. These links to third party websites are provided as a convenience and are for informational purposes only. The Company does not endorse, and is not responsible for, these linked websites.
 
Although you are not required to register to access our Sites, you may be asked to provide us with personal and/or company information when you visit certain sections of our Sites. Your use of our Sites signifies your acknowledgement and consent to our Privacy Policy. If you do not agree to this Privacy Policy, please do not continue to use our Sites. Your continued use of the Sites signifies your acceptance of these terms and any changes in effect at the time of use.
 
 
<br/><br/><strong>COLLECTION OF PERSONAL INFORMATION</strong><br/>
Personal Information is information about you that identifies you as an individual, for example, your name, address, e-mail address, or telephone number.  Company data include and not limited to type of business, bank, branch, checking account and bank contact.
 
When you register on our website and/or decide to purchase our products and services, you authorize us to use the information collected as mentioned herein.  Usage of information include but not limited to credit checks.
We collect information that you voluntarily provide to us through responses to surveys, search functions, questionnaires, feedback, forms, blogs and the like.  
​
 
<br/><br/><strong>HOW DOES COMPANY USE INFORMATION GATHERED ABOUT ONLINE VISITORS?</strong><br/>
Before forwarding us any personal and/or company information, please be advised that any information gathered on our Sites may be used in the aggregate for research and development relating to our Sites and/or for future site development and, if you ask us to, to send you promotional materials. In particular, we may use information gathered about you for the following purposes: to monitor interest in our range of products and to assist us to tailor the content of our Sites to your needs by collecting information about your preferences through tracking of patterns page views on our Sites; to create a profile relating to you in order to show you the content that might be of interest to you and to display the content according to your preferences; and, in circumstances where you have indicated that you wish to receive additional information, to send you information about us and promotional material about our products together with details of any offers we may have available from time to time.
​
 
<br/><br/><strong>PROMOTIONAL AND INFORMATIONAL OFFERS</strong><br/>
With the permission of an online visitor, information submitted at the time of registration or submission may be used for marketing and promotional purposes by the Company provided notice of this fact is made available online. If a visitor objects to such use for any reason, he/she may prevent that use, either by e-mail request or by modifying the registration information provided. The Company uses reasonable efforts to maintain visitors' information in a secure environment. If you have submitted personal information and want to change it or opt-out, please contact us through e-mail.
​
 
<br/><br/><strong>DISCLOSURE OF INFORMATION</strong><br/>
The Company will not disclose personal information that you provide on its Sites to any third parties other than to a Company agent except: i) in accordance with the terms of this Privacy Policy, or ii) to comply with legal requirements such as a law, regulation, warrant, subpoena or court order, and/or iii) if you are reporting an adverse event/side effect, in which case the Company may be required to disclose such information to bodies such as, but not limited to, Canadian and/or international regulatory authorities. Please note that any of these disclosures may involve the storage or processing of personal information outside of Canada and may therefore be subject to different privacy laws than those applicable in Canada, including laws that require the disclosure of personal information to governmental authorities under circumstances that are different than those that apply in Canada.
 
 
<br/><br/><strong>COOKIES</strong><br/>
The Company, in common with many web site operators, may use standard technology called "cookies" on its Sites. Cookies are small data files that are downloaded onto your computer when you visit a particular web site. You can disable cookies by turning them off in your browser; however, some areas of the Sites, such as abandoned shopping carts, may not function properly if you do so.
 
​
 
<br/><br/><strong>ADDITIONAL TERMS FOR CERTAIN WEBSITES</strong><br/>
The following additional information applies to our Sites that require registration. Generally, you are not required to provide personal information as a condition of using our Sites, except as may be necessary to provide you with a product or service that you have requested. However, some of our Sites are restricted to certain individuals such as our registered customers and we may require these individuals to register upon entry by providing us with certain information.
 
​
<br/><br/><strong>POLICY CHANGE:</strong><br/>
 
If we alter our Privacy Policy, any changes will be posted on this page of our Site so that you are always informed of the information we collect about you and/or your company, how we use it and the circumstances under which we may disclose it.
 
 
<br/><br/><strong>ACCEPTANCE OF OUR PRIVACY POLICY:</strong><br/>
By using this Site or any other The Company Site or interactive banner ads, you signify your acceptance of our Privacy Policy, and you adhere to the terms and conditions posted on the Site. By submitting your information, you agree that it will be governed by our Privacy Policy.

                    </p>
                </div>
            </div>
        </Layout>
    )
}
export default Privacy
